import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import { SocialIcon } from 'react-social-icons';

import TimeIcon from '../../../../assets/images/icons/time.svg';
import './SocialPost.scss';

const MAX_LENGTH = 100;

const SocialPost = ({ icon, name, content, url, username, date }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const capitalizeFirstLetter = (string) => {
    if (!string) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="social-post">
      <div className="social-header">
        <div className="social-header-icon">
          <SocialIcon url={name === 'Youtube' ? url : icon} />
        </div>
        <div className="social-header-label">
          {name}
          {name !== 'News' ? <span>{username}</span> : ''}
        </div>
      </div>
      <div className="social-text">
        {name === 'News' ? (
          <>
            <div className="news-content primary">{capitalizeFirstLetter(username)}</div>
            <div className="news-content">{content}</div>
          </>
        ) : name === 'Youtube' ? (
          <div className="video">
            <ReactPlayer url={url} controls={true} light={true} width="100%" />
            {content && (
              <>
                <p className="video__text">
                  {isExpanded ? content : content.slice(0, MAX_LENGTH) + (content.length > MAX_LENGTH ? '...' : '')}
                </p>
                {!isExpanded && content.length > MAX_LENGTH && (
                  <span className="video__btn link" onClick={() => setIsExpanded(true)}>
                    ...read more
                  </span>
                )}
              </>
            )}
          </div>
        ) : (
          content
        )}
      </div>
      <div className="social-date">
        <img src={TimeIcon} /> Posted On: {date}
      </div>
    </div>
  );
};

export default SocialPost;
