import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DataGridComponent from '../../Forms/DataGrid/DataGrid';
import Fader from '../../Loaders/Fader';

import { getKeywords } from '../../../../http/dataAPI';
import { getCustomState } from '../../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../../util/constants/AppConstants';

import KeywordIcon from '../../../../assets/images/icons/keyword.svg';
import './Keywords.scss';

const Keywords = ({ activeTopicId, userDataState }) => {
  const config = useSelector((state) => state.config.config);
  const session = getCustomState(SESSION_STATE);

  const [loading, setLoading] = useState(false);
  const [keywordsData, setKeywordsData] = useState([]);

  const handleGetTopKeywords = async (id, userId) => {
    setLoading(true);
    setKeywordsData([]);

    const res = await getKeywords(config, id, userId);
    if (res.status === 'success') {
      setKeywordsData(res.data);
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Top Keywords');
    }
  };

  useEffect(() => {
    if (config.api && session) {
      const userData = getCustomState(USER_DATA, true);
      handleGetTopKeywords(activeTopicId || 0, userData.id);
    }
  }, [config, session, userDataState, activeTopicId]);

  return (
    <div className="mentions right-mentions chart-component keywords-chart">
      <div className="title-top">
        <div className="chart-title align-left">
          <img src={KeywordIcon} /> Top Keywords
        </div>
      </div>
      <div className="sentiment-charts">
        {!loading ? (
          keywordsData.map((link, index) => (
            <DataGridComponent key={index} dataNumber={index + 1} data={link} type={'keyword'} truncateText={false} />
          ))
        ) : (
          <Fader />
        )}
      </div>
    </div>
  );
};

export default Keywords;
