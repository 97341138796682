import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Fader from '../../Loaders/Fader';

import { getSummary } from '../../../../http/dataAPI';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { formatDate } from '../../../../util/HelperFunctions';

import ArrowIcon from '../../../../assets/images/icons/arrow-right-blue.svg';
import './Summary.scss';

const Summary = ({ activeTopicId }) => {
  const config = useSelector((state) => state.config.config);

  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [activeSummary, setActiveSummary] = useState(null);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const dropdownRef = useRef();
  const dropdownLabelRef = useRef();
  const scrollbarRef = useRef();

  useEffect(() => {
    if (isDropdownOpened && scrollbarRef.current) {
      scrollbarRef.current.scrollTop = 1;
    }
  }, [isDropdownOpened]);

  const handleGetSummaryData = async (searchId) => {
    setLoading(true);
    setSummaryData(null);
    setActiveSummary(null);

    const res = await getSummary(config, searchId);
    if (res.status === 200) {
      if (res.data) {
        setSummaryData(res.data);
        setActiveSummary(res.data[0]);
      }
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Summary data');
    }
  };

  useEffect(() => {
    if (config.api) {
      handleGetSummaryData(activeTopicId || 0);
    }
  }, [config, activeTopicId]);

  const handleDropDownClick = () => {
    if (summaryData.length > 1) {
      setIsDropdownOpened((prev) => !prev);
    }
  };

  const handleDateClick = (summary) => {
    setActiveSummary(summary);
    setIsDropdownOpened(false);
  };

  useOutsideClick(dropdownRef, dropdownLabelRef, isDropdownOpened, () => setIsDropdownOpened(false));

  return (
    <div className="summary chart-component">
      <div className="chart-title align-left">
        AI Topic Summary
        {!loading && summaryData && (
          <div className={`date ${summaryData.length > 1 ? 'pointer' : ''}`}>
            <div className="date__active" ref={dropdownLabelRef} onClick={handleDropDownClick}>
              {formatDate(activeSummary.date)}
              {summaryData.length > 1 && (
                <img className={`navigation__arrow ${isDropdownOpened ? 'rotated' : ''}`} src={ArrowIcon} />
              )}
            </div>
            {isDropdownOpened && (
              <div
                className={`options-wrapper ${summaryData.length > 1 && summaryData.length < 7 ? `n${summaryData.length}` : ''}`}
                ref={dropdownRef}
              >
                <PerfectScrollbar
                  ref={scrollbarRef}
                  options={{
                    wheelPropagation: false,
                    autoHide: false,
                  }}
                >
                  <ul className="options-list">
                    {summaryData.map((summary) => (
                      <li
                        key={summary.id}
                        className={summary.id === activeSummary.id ? 'active' : ''}
                        onClick={() => handleDateClick(summary)}
                      >
                        {formatDate(summary.date)}
                      </li>
                    ))}
                  </ul>
                </PerfectScrollbar>
              </div>
            )}
          </div>
        )}
      </div>
      {!loading ? (
        summaryData ? (
          <ul className="summary__list">
            {activeSummary.summary.map((elem, i) => (
              <li key={i} className="summary__item">
                {elem}
              </li>
            ))}
          </ul>
        ) : (
          <div className="no-data">No Data Available. Please Check Back Shortly.</div>
        )
      ) : (
        <div className="sentiment-charts">
          <Fader />
        </div>
      )}
    </div>
  );
};

export default Summary;
