import React, { useMemo, useRef, useState } from 'react';

import { PieChart } from '@mui/x-charts';
import Fader from '../../Loaders/Fader';

import useOutsideClick from '../../../../hooks/useOutsideClick';

import './OverallSentiment.scss';

const OverallSentiment = ({ handleFaqPage, loading, overallSentimentPositive, overallSentimentNegative }) => {
  const [faq, setFaq] = useState(false);

  const faqRef = useRef();
  const faqIconRef = useRef();

  const data = [
    { label: 'Negative', value: overallSentimentNegative, color: '#F3072C' },
    { label: 'Positive', value: overallSentimentPositive, color: '#05B08A' },
  ];

  const negativePercentage = useMemo(() =>
    Math.round((overallSentimentNegative / (overallSentimentPositive + overallSentimentNegative)) * 100),
  );
  const positivePercentage = useMemo(() =>
    Math.round((overallSentimentPositive / (overallSentimentPositive + overallSentimentNegative)) * 100),
  );

  useOutsideClick(faqRef, faqIconRef, faq, () => setFaq(false));

  const toggleFaq = () => {
    setFaq((prevState) => !prevState);
  };

  const showFullFaq = () => {
    handleFaqPage();
    setFaq(false);
  };

  return (
    <div className="overall-sentiment chart-component">
      <div ref={faqIconRef} className="info-icon" onClick={toggleFaq}>
        i
      </div>
      {faq && (
        <div className="info-box" ref={faqRef}>
          <div className="ii-copy">
            <div className="iic-exp">
              Definition: Overall Sentiment provides a cumulative sentiment rating from all sources, giving you a
              big-picture view of how your brand or topic is perceived.
            </div>
            <div className="iic-sub">How to Use</div>
            <ol>
              <li>Look for the 'Overall Sentiment' section, often presented as a gauge or summary statistic.</li>
              <li>Assess the overarching sentiment score or percentage.</li>
              <li>Utilize this data to gauge public perception</li>
            </ol>
            <span className="link" onClick={showFullFaq}>
              See All
            </span>
          </div>
        </div>
      )}
      <div className="chart-title">Overall Sentiment</div>
      <div className="sentiment-charts">
        {!loading ? (
          <>
            <PieChart
              series={[
                {
                  startAngle: -90,
                  endAngle: 90,
                  paddingAngle: 2,
                  innerRadius: 100,
                  outerRadius: 90,
                  color: '#333',
                  data,
                },
              ]}
              margin={{ right: 5, top: 20 }}
              width={200}
              height={200}
              slotProps={{
                legend: { hidden: true },
              }}
            />
            <div className="chart-percentages">
              <div className="cp-negative">{negativePercentage}% Negative</div>
              <div className="cp-positive">{positivePercentage}% Positive</div>
            </div>
          </>
        ) : (
          <Fader />
        )}
      </div>
    </div>
  );
};

export default OverallSentiment;
