import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DataGridComponent from '../../Forms/DataGrid/DataGrid';
import Fader from '../../Loaders/Fader';

import { getTopSharedLinks } from '../../../../http/dataAPI';
import { getCustomState } from '../../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../../util/constants/AppConstants';

import ShareIcon from '../../../../assets/images/icons/share.svg';
import './SharedLinks.scss';

const SharedLinks = ({ activeTopicId, userDataState }) => {
  const config = useSelector((state) => state.config.config);
  const session = getCustomState(SESSION_STATE);

  const [loading, setLoading] = useState(false);
  const [sharedLinksData, setSharedLinksData] = useState([]);

  const handleGetTopSharedLinks = async (id, userId) => {
    setLoading(true);
    setSharedLinksData([]);

    const res = await getTopSharedLinks(config, id, userId);
    if (res.status === 'success') {
      setSharedLinksData(res.data);
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Top Shared Links');
    }
  };

  useEffect(() => {
    if (config.api && session) {
      const userData = getCustomState(USER_DATA, true);
      handleGetTopSharedLinks(activeTopicId || 0, userData.id);
    }
  }, [config, session, userDataState, activeTopicId]);

  return (
    <div className="mentions chart-component shared-links">
      <div className="title-top">
        <div className="chart-title align-left">
          <img src={ShareIcon} /> Top Shared Links
        </div>
      </div>
      <div className="sentiment-charts">
        {!loading ? (
          sharedLinksData.map((link, index) => (
            <DataGridComponent key={index} dataNumber={index + 1} data={link} type={'link'} />
          ))
        ) : (
          <Fader />
        )}
      </div>
    </div>
  );
};

export default SharedLinks;
