import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as pdfjsLib from 'pdfjs-dist/webpack';

import Fader from '../app/components/Loaders/Fader';
import Tooltip from '../app/components/Tooltip/Tooltip';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PDFToImage = ({ pdfKey, jpgKey }) => {
  const config = useSelector((state) => state.config.config);

  const [imageSrc, setImageSrc] = useState(null);
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: -45 });

  const renderTaskRef = useRef(null);

  useEffect(() => {
    const renderPDF = async () => {
      try {
        const pdf = await pdfjsLib.getDocument(`${config.api.cdn}/${pdfKey}`).promise;
        const page = await pdf.getPage(1);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport,
        };

        if (renderTaskRef.current) {
          renderTaskRef.current.cancel();
        }

        const renderTask = page.render(renderContext);
        renderTaskRef.current = renderTask;

        await renderTask.promise;
        setImageSrc(canvas.toDataURL());
      } catch (error) {
        if (error.name !== 'RenderingCancelledException') {
          console.error('Failed to render PDF:', error);
        }
      }
    };

    setImageSrc(null);

    if (jpgKey) {
      const img = new Image();
      img.src = jpgKey;
      img.onload = () => {
        setImageSrc(jpgKey);
      };
    } else {
      renderPDF();
    }

    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
  }, [pdfKey]);

  const handleOpenImg = () => {
    window.open(`${config.api.cdn}/${pdfKey}`, '_blank');
  };

  const handleMouseMove = (event) => {
    setTooltipPos({ x: event.clientX, y: event.clientY });
  };

  if (imageSrc) {
    return (
      <>
        <img
          className="map__image"
          src={imageSrc}
          alt="Electoral map"
          onClick={handleOpenImg}
          onMouseMove={handleMouseMove}
        />
        <Tooltip content="Click to open the PDF" x={tooltipPos.x} y={tooltipPos.y} />
      </>
    );
  }

  return <Fader />;
};

export default PDFToImage;
