import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCustomState, saveCustomState } from './LocalStorage';
import { setConfig } from '../datastore/actions/configActions';
import configurations from '../config.json';
import { BACKGROUND_EFFECT, SESSION_STATE } from './constants/AppConstants';

const Preload = () => {
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session.session);
  const config = useSelector((state) => state.config.config);
  const settings = useSelector((state) => state.settings);

  const sessionState = getCustomState(SESSION_STATE);
  const backgroundEffect = getCustomState(BACKGROUND_EFFECT);

  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    if (!config.length) {
      dispatch(setConfig(configurations));
      setConfigLoaded(true);
    }

    if (session && !sessionState) {
      saveCustomState(SESSION_STATE, session);
    }
    if (settings && !backgroundEffect) {
      saveCustomState(BACKGROUND_EFFECT, settings.backgroundEffect);
    }
  }, [session, sessionState, settings]);

  return null;
};

export default Preload;
