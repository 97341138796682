import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import LineChartComponent from '../../Charts/LineChart';
import Fader from '../../Loaders/Fader';
import Tooltip from '../../Tooltip/Tooltip';

import { getEngagementsData } from '../../../../http/dataAPI';
import { getCustomState } from '../../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../../util/constants/AppConstants';
import { abbreviateNumber } from '../../../../util/HelperFunctions';

import EngagementIcon from '../../../../assets/images/icons/engagement.svg';
import './Engagements.scss';

const Engagements = ({ activeTopicId, userDataState }) => {
  const config = useSelector((state) => state.config.config);
  const session = getCustomState(SESSION_STATE);

  const [loading, setLoading] = useState(false);
  const [engagementsDivWidth, setEngagementsDivWidth] = useState(250);
  const [engagementsData, setEngagementsData] = useState([]);
  const [totalEngagements, setTotalEngagements] = useState('0');
  const [totalDailyEngagements, setTotalDailyEngagements] = useState('0');
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: -45 });

  const engagementsRef = useRef();

  useLayoutEffect(() => {
    const handleResize = () => {
      if (engagementsRef.current) {
        setEngagementsDivWidth(engagementsRef.current.offsetWidth - 85);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   if (loading && engagementsRef.current) {
  //     setEngagementsDivWidth(engagementsRef.current.offsetWidth - 85);
  //   }
  // }, [loading]);

  const handleGetEngagementsData = async (id, userId) => {
    setLoading(true);
    setEngagementsData([]);
    setTotalEngagements('0');
    setTotalDailyEngagements('0');

    const res = await getEngagementsData(config, id, userId);
    if (res.status === 'success') {
      const { data, total, avg } = res.data;
      setEngagementsData(data);
      setTotalEngagements(total);
      setTotalDailyEngagements(avg);
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Engagements data');
    }
  };

  useEffect(() => {
    if (config.api && session) {
      const userData = getCustomState(USER_DATA, true);
      handleGetEngagementsData(activeTopicId || 0, userData.id);
    }
  }, [config, session, userDataState, activeTopicId]);

  const handleMouseMove = (event) => {
    setTooltipPos({ x: event.clientX, y: event.clientY });
  };

  return (
    <div ref={engagementsRef} className="mentions right-mentions chart-component engagements-chart">
      <div className="title-top">
        <div className="tt-left">
          <div className="chart-title align-left">
            <img src={EngagementIcon} /> Engagements
          </div>
        </div>
        <div className="tt-right">
          <div className="tt-right-total" onMouseMove={handleMouseMove}>
            <div className="ttr-top">Total Engagements</div>
            <div className="ttr-bottom">{loading ? <Fader /> : abbreviateNumber(totalEngagements)}</div>
            <Tooltip content={totalEngagements} x={tooltipPos.x} y={tooltipPos.y} />
          </div>
          <div className="tt-right-total border-left" onMouseMove={handleMouseMove}>
            <div className="ttr-top">Daily Engagements</div>
            <div className="ttr-bottom">{loading ? <Fader /> : abbreviateNumber(totalDailyEngagements)}</div>
            <Tooltip content={totalDailyEngagements} x={tooltipPos.x} y={tooltipPos.y} />
          </div>
        </div>
      </div>

      <div className="sentiment-charts">
        {!loading ? (
          <LineChartComponent
            data={engagementsData}
            width={engagementsDivWidth}
            xAxisData={engagementsData}
            keyToLabel={{ value: 'Total Engagements' }}
            colorMap={{ value: '#376EBA' }}
            useXAxis={true}
            useArea={true}
          />
        ) : (
          <Fader />
        )}
      </div>
    </div>
  );
};

export default Engagements;
