import React from 'react';

import './MarketPulse.scss';

const MarketPulse = () => {
  return (
    <div className="platform-frame">
      <div className="marketpulse">
        <div className="marketpulse__iframe">
          <iframe
            id="theiframe"
            title="REPORT"
            width="100%"
            height="100%"
            src="https://experience.arcgis.com/experience/22a4212482c747b7aa8c4ddcf1392ddb/"
            frameborder="0"
            allowFullScreen="true"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MarketPulse;
