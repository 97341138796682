export const setAxiosConfig = (config, uri, type = 'get', params, upload = false, isDev) => {
  let { endpoint } = config.api;
  if (config.api.useport && config.api.port) {
    endpoint += `:${config.api.port}`;
  }

  if (!endpoint) {
    return false;
  }

  if (isDev) {
    endpoint = config.api.endpoint2;
  }

  const token = localStorage.getItem('token') ?? false;
  const tokenData = JSON.parse(token);

  const axiosConfig = {
    method: type,
    url: endpoint + uri,
    headers: {
      'Content-Type': upload ? 'multipart/form-data' : 'application/json',
      'x-api-key': config.api.pubkey,
      'x-token-key': tokenData ? tokenData.token : 0,
      'x-token-id': tokenData ? tokenData.id : 0,
    },
    ...(params ? { data: params } : {}),
  };

  return axiosConfig;
};
