/**
 *  @ These are all the app specific constants we will be using:
 */
export const SESSION_STATE = 'SESSION_STATE';
export const SESSION_ID = 'SESSION_ID';
export const USER_DATA = 'USER_DATA';
export const BACKGROUND_EFFECT = 'BACKGROUND_EFFECT';

export const USER_ROLES = [
  {
    label: 'Administrator',
    short: 'Admin',
    value: 1,
  },
  {
    label: 'Beta User',
    short: 'Beta',
    value: 2,
  },
  {
    label: 'General User',
    short: 'General',
    value: 3,
  },
];

export const units = ['', 'K', 'M', 'B', 'T', 'Q'];

export const partyColors = [
  {
    party: 'Progressive Conservative',
    color: '#9999FF',
  },
  {
    party: 'New Democratic',
    color: '#F4A460',
  },
  {
    party: 'Liberal',
    color: '#EA6D6A',
  },
  {
    party: 'Green',
    color: '#99C955',
  },
  {
    party: 'New Blue',
    color: '#02478d',
  },
  {
    party: 'Ontario Party',
    color: '#112f53',
  },
  {
    party: 'Consensus Ontario',
    color: '#00BAF2',
  },
  {
    party: 'Independent',
    color: '#DCDCDC',
  },
];
