import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';
import SocialPost from '../SocialPost/SocialPost';
import Fader from '../../Loaders/Fader';

import { getTopNewsPosts } from '../../../../http/dataAPI';
import { getCustomState } from '../../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../../util/constants/AppConstants';

import './NewsPosts.scss';

const NewsPosts = ({ activeTopicId, userDataState }) => {
  const config = useSelector((state) => state.config.config);
  const session = getCustomState(SESSION_STATE);

  const [loading, setLoading] = useState(false);
  const [newsPostsData, setNewsPostsData] = useState([]);

  const handleGetAllTopNewsPosts = async (id, userId) => {
    setLoading(true);
    setNewsPostsData([]);

    const res = await getTopNewsPosts(config, id, userId);
    if (res.status === 'success') {
      setNewsPostsData(res.data);
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Top News Posts');
    }
  };

  useEffect(() => {
    if (config.api && session) {
      const userData = getCustomState(USER_DATA, true);
      handleGetAllTopNewsPosts(activeTopicId || 0, userData.id);
    }
  }, [config, session, userDataState, activeTopicId]);

  return (
    <div className="top-posts chart-component">
      <div className="chart-title align-left">Top News Posts</div>
      {!loading ? (
        newsPostsData.length ? (
          <div className="top-posts-list">
            <PerfectScrollbar
              options={{
                wheelPropagation: true,
                autoHide: false,
                wheelSpeed: 0.7,
              }}
            >
              <div className="top-posts-wrap">
                {newsPostsData.map((post, index) => (
                  <SocialPost
                    key={index}
                    name={post.source}
                    icon={post.icon}
                    content={post.text}
                    username={post.username}
                    date={post.date}
                  />
                ))}
              </div>
            </PerfectScrollbar>
          </div>
        ) : (
          <div className="no-data">No Data Available. Please Check Back Shortly.</div>
        )
      ) : (
        <div className="sentiment-charts">
          <Fader />
        </div>
      )}
    </div>
  );
};

export default NewsPosts;
