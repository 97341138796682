import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

import useGAPageTracking from '../hooks/useGAPageTracking';

const GoogleAnalytics = () => {
  const config = useSelector((state) => state.config.config);

  useEffect(() => {
    if (config.api) {
      ReactGA.initialize(config.api.gaId);
    }
  }, [config]);

  useGAPageTracking();
};

export default GoogleAnalytics;
