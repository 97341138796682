import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import LineChartComponent from '../../Charts/LineChart';
import Fader from '../../Loaders/Fader';

import { getSentimentTrendData } from '../../../../http/dataAPI';
import { getCustomState } from '../../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../../util/constants/AppConstants';

import TrendIcon from '../../../../assets/images/icons/trend.svg';
import './SentimentTrend.scss';

const SentimentTrend = ({ activeTopicId, userDataState }) => {
  const config = useSelector((state) => state.config.config);
  const session = getCustomState(SESSION_STATE);

  const [loading, setLoading] = useState(false);
  const [trendDivWidth, setTrendDivWidth] = useState(250);
  const [sentimentTrendXAxisData, setSentimentTrendXAxisData] = useState([]);

  const trendRef = useRef();

  useLayoutEffect(() => {
    const handleResize = () => {
      if (trendRef.current) {
        setTrendDivWidth(trendRef.current.offsetWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   if (loading && trendRef.current) {
  //     setTrendDivWidth(trendRef.current.offsetWidth);
  //   }
  // }, [loading]);

  const handleGetSentimentTrendData = async (id, userId) => {
    setLoading(true);
    setSentimentTrendXAxisData([]);

    const res = await getSentimentTrendData(config, id, userId);
    if (res.status === 'success') {
      const sentimentTrendData = res.data;
      setSentimentTrendXAxisData(sentimentTrendData);
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Sentiment Trend data');
    }
  };

  useEffect(() => {
    if (config.api && session) {
      const userData = getCustomState(USER_DATA, true);
      handleGetSentimentTrendData(activeTopicId || 0, userData.id);
    }
  }, [config, session, userDataState, activeTopicId]);

  return (
    <div className="sentiment-trend chart-component">
      <div ref={trendRef} className="chart-title align-left">
        <img src={TrendIcon} /> Sentiment Trend
      </div>
      {!loading ? (
        <LineChartComponent width={trendDivWidth} xAxisData={sentimentTrendXAxisData} useXAxis={true} />
      ) : (
        <div className="sentiment-charts">
          <Fader />
        </div>
      )}
    </div>
  );
};

export default SentimentTrend;
