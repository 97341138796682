import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputItem from '../../components/Forms/InputItem';
import FormButton from '../../components/Forms/FormButton';
import Fader from '../../components/Loaders/Fader';
import AppNotify from '../../components/AppNotify/AppNotify';

import { login } from '../../../http/authAPI';
import { setSession, setSessionId } from '../../../datastore/actions/sessionActions';
import { setLoggingIn, setNotify } from '../../../datastore/actions/actionActions';
import { saveCustomState } from '../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA, SESSION_ID } from '../../../util/constants/AppConstants';

import Logo from '../../../assets/images/logo.png';
import UsernameIcon from '../../../assets/images/icons/user.svg';
import PasswordIcon from '../../../assets/images/icons/password.svg';
import './Login.scss';

const Login = () => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggingIn, setIsLoggingIn] = useState(false);
  const [loginChallenge, setLoginChallenge] = useState(false);

  const handleUsername = (e) => {
    const usernameValue = e.currentTarget.value;
    setUsername(usernameValue);
  };

  const handlePassword = (e) => {
    const passwordValue = e.currentTarget.value;
    setPassword(passwordValue);
  };

  const handleLogin = async () => {
    if (!username || !password) {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: 'Password and/or Email Missing',
        }),
      );
      return;
    }

    setIsLoggingIn(true);
    setLoginChallenge(true);
    dispatch(setLoggingIn(true));

    const res = await login(config, username, password);

    if (res.status === 'success') {
      const userData = res.data;

      saveCustomState(
        USER_DATA,
        {
          id: userData.user.id,
          firstname: userData.user.firstname,
          lastname: userData.user.lastname,
          email: userData.user.email,
          role: userData.user.role,
          job: userData.user.job_title,
        },
        true,
      );

      dispatch(setSessionId(userData.session.id));
      dispatch(setSession(userData.session.token));

      saveCustomState(SESSION_STATE, userData.session.token);
      saveCustomState(SESSION_ID, userData.session.id);

      setTimeout(() => {
        dispatch(setLoggingIn(false));
        setLoginChallenge(false);
      }, 500);
    } else {
      if (res.message === 'Incorrect Password.' || res.message === 'No User Found.') {
        setPassword('');
      }

      setIsLoggingIn(false);
      setLoginChallenge(false);
      dispatch(setLoggingIn(false));
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleForgotPassword = () => {};

  return (
    <>
      <AppNotify />
      <div className={`login-wrap ${loginChallenge ? 'logging-in' : ''}`}>
        <div className="login-view">
          <div className="login-box">
            <div className="login-logo">
              <img src={Logo} />
            </div>
            <div className="tagline">Data Is the New Oil</div>
            <div className="login-fields">
              <InputItem
                label="Username"
                type={'text'}
                icon={UsernameIcon}
                value={username}
                onChange={handleUsername}
                onKeyDown={handleKeyDown}
              />
              <InputItem
                label="Password"
                type={'password'}
                icon={PasswordIcon}
                value={password}
                onChange={handlePassword}
                onKeyDown={handleKeyDown}
              />
              <div className="submit">
                {/* <FormButton text={'Login'} onClick={handleLogin} subLink={'Forgot Password'} subLinkClick={handleForgotPassword} /> */}
                <FormButton text={'Login'} onClick={handleLogin} />
              </div>
            </div>
            {loginChallenge && <Fader size={50} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
